import React from "react";
import { Link } from "gatsby";
import {
  IoIosLock,
  IoIosContact,
  IoIosFlash,
  IoIosTime,
  IoIosPaperPlane,
  IoIosHeart,
  IoIosPhonePortrait,
  IoIosHappy,
  IoIosCheckmarkCircle,
} from "react-icons/io";
import { useModal } from "../components/useModal";
import styled from "styled-components";

import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Image from "../components/image";
import Seo from "../components/seo";
import {
  Modal,
  CustomerQuote,
  ProductPricing,
  CallToAction,
} from "../components/site";

const Award = styled(Row)`
  padding: 15px;
  border: solid 1px #eee;
  border-radius: ${(props) => props.theme.styles.borderRadius};
  width: max-content;

  .award-logo {
    display: block;
    width: 50px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
  }
`;

const SmartlyPayroll = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  return (
    <Layout>
      <Seo
        title="Switch from Smartly to PayHero Online Payroll"
        description="Converting from Smart Payroll to PayHero is free & easy and we’ll help you every step of the way."
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={70} maxWidth={1200} noPaddingBottom>
          <Box size={100} stackGap={40} centerOnMobile>
            <Box stackGap={10}>
              <h1 className="-textCenter -fontLarge">
                Switch from Smartly{" "}
                <span className="-fontPrimary">to PayHero</span>
              </h1>
              <h4 className="-textCenter -fontNormal">
                Take the stress out of payday and enjoy smarter payroll with
                PayHero. Converting from Smart Payroll to PayHero is free & easy
                and we’ll help you every step of the way.
              </h4>
            </Box>
            <Box stackGap={20}>
              <FlexButtons justify="center">
                <Button className="primary -lg gtm-cta" to="/signup">
                  Try PayHero for Free
                </Button>
                <Button
                  className="secondary -lg gtm-cta"
                  onClick={toggleModal}
                  atag
                >
                  Book a Call
                </Button>
              </FlexButtons>
              <p className="-textCenter" css={{ color: "#999" }}>
                14 Days Free · First Pay Walkthrough · No Credit Card Required
              </p>
            </Box>
            <Award
              className="-center"
              justify="center"
              stackGap={15}
              alignCenter
            >
              <div className="award-logo">
                <Image
                  filename="PayrollPartnerWinner2021.png"
                  alt="PayHero was awarded ICNZB Payroll Software Partner of the Year for 2021"
                  maxWidth={50}
                />
              </div>
              <p className="-textCenter" css={{ marginBottom: "0" }}>
                NZ Bookkeepers Payroll Software Partner of the Year
              </p>
            </Award>
          </Box>
          <Box>
            <Image
              filename="PayHeroHero_Cropped.png"
              alt="PayHero"
              maxWidth={1000}
              centerImage
              addShadow
            />
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey" className="-textCenter">
        <Wrapper>
          <h2>
            Looking for a Smart Payroll alternative? Here's why PayHero works
          </h2>
          <Box stackGap={50}>
            <Row stackGap={50}>
              <Box size={33}>
                <h3>
                  <IoIosHappy />
                  <br />
                  Expert support
                </h3>
                <p>
                  Our friendly Wellington-based support team is available by
                  phone or email to help you out whenever you need it.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosPhonePortrait />
                  <br /> Employee App
                </h3>
                <p>
                  Stress-free payroll & employee app with timesheets, leave,
                  expenses & payslip access.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosCheckmarkCircle />
                  <br /> Value for money
                </h3>
                <p>
                  Unhappy about getting hit with a price increase? PayHero
                  provides an affordable and honest solution for your business.
                </p>
              </Box>
            </Row>
            <Row stackGap={50}>
              <Box size={33}>
                <h3>
                  <IoIosLock />
                  <br />
                  Take compliance seriously
                </h3>
                <p>
                  PayHero was built to follow the latest Holidays Act guidance
                  published by MBIE. We store annual leave in weeks and review
                  recent work patterns when leave is taken to determine the
                  right entitlements.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHeart />
                  <br />
                  No sign up fee
                </h3>
                <p>
                  There are no hidden costs or sign up fees with PayHero.{" "}
                  <Link to="/signup?utm_term=smartly">
                    Try it for free for 14 days
                  </Link>{" "}
                  then choose the plan that works for you.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosPaperPlane />
                  <br />
                  Process pays on your terms
                </h3>
                <p>
                  When you run a pay, you can transfer the funds with a POLi
                  payment, or use our industry standard bank files, leaving you
                  in complete control of your finances.
                </p>
              </Box>
            </Row>
            <Row stackGap={50}>
              <Box size={33}>
                <h3>
                  <IoIosTime />
                  <br />
                  Digital timesheets and leave management for all
                </h3>
                <p>
                  Digital employee timesheets and advanced leave management are
                  included in every plan. Go paperless with integrated online
                  payroll and timesheets.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosFlash />
                  <br /> Automatically update your accounts
                </h3>
                <p>
                  Seamlessly send your payroll transactions to Xero and assign
                  wage costs to different account codes or tracking categories.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosContact />
                  <br /> Accurately pay variable hour employees
                </h3>
                <p>
                  Track hourly work with ease and automatically calculate pay
                  and entitlements for part time, casual, contract or shift
                  workers.
                </p>
              </Box>
            </Row>
          </Box>
          <CustomerQuote
            quote="We’re usually reluctant to endorse payroll software, simply because the cost of getting it wrong is huge. PayHero gets the detail right, at the same time as being exceptionally easy to use. In short, it’s second to none."
            name="Hamish Mexted"
            company="Convex Accounting"
            pic="Hamish_Circle.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={60}>
            <div className="-textCenter">
              <h2>PayHero Pricing</h2>
              <h4>No Hidden Costs · Easy Set Up · 14 Day Free Trial</h4>
            </div>
            <ProductPricing selectedProduct="payhero" hideAddOns />
            <p className="-textCenter" css={{ color: "#999" }}>
              Prices are in NZD and exclude GST.
            </p>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="lightGrey">
        <Wrapper>
          <h2 className="-textCenter">A bit about us</h2>
          <Row alignCenter stackGap={70}>
            <Box size={50}>
              <Image
                filename="FlexiTime_ABV2021.jpg"
                alt="PayHero Team 2021 at ABV Rootfop Bar"
                addShadow
                rounded
              />
            </Box>
            <Box size={50} stackGap={20}>
              <h4>
                We're a New Zealand owned and operated technology company that
                has been helping thousands of Kiwi companies get their payroll
                right since 2008.
              </h4>
              <h4>
                Our payroll product, PayHero, is perfect for Kiwi companies that
                want stress free and compliant online payroll. Following the
                very latest Holidays Act guidance from MBIE, PayHero stores
                employee leave balances in weeks so companies with staff who
                work variable hours or have changing work patterns always get
                their leave entitlements correct.
              </h4>
              <h4>
                Better yet, the key elements that feed into payroll are all
                included - online timesheets; employee leave management; fully
                automated payday filing; expense claims and reimbursements;
                automatic calculation of public holiday entitlements; rest and
                meal break management; and deep integration with Xero.
              </h4>
              <h4>
                Our team would love to help you make the switch from Smartly to
                PayHero. <Link to="/contact">Get in touch with us today</Link>!
              </h4>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <CallToAction
        title={[
          <span className="-fontPrimary">Swap from Smart Payroll today </span>,
          "and find out why NZ bookkeepers love PayHero with a Free Trial",
        ]}
        buttonText="Get Smarter Payroll Now"
        buttonLink="/signup?utm_term=smartly"
        showSalesButton
        hideDesc
      />
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default SmartlyPayroll;
